export function formatCurrency (value = 0, withCurrency = true) {
  const formatted = new Intl.NumberFormat().format(value)
  return `${withCurrency ?  `Rp. ${formatted}` : formatted}`
}

export function formatDatepicker (value) {
  const date = new Date(value)
  return `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`
}

export function formatDateMonthName (value) {
  const date = new Date(value)
  return `${date.getUTCDate()} ${date.toLocaleString('default', { month: 'long' })} ${date.getUTCFullYear()}`
}
